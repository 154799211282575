import React, { type ReactElement, type ReactNode } from "react";

import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import _ from "lodash";
import { Global, css } from "@emotion/react";
import * as Sentry from "@sentry/nextjs";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import {
  type DehydratedState,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import ErrorBoundary from "~/components/ErrorBoundary";
import { IS_PROD } from "~/config";
import { HistoryManagerProvider, useHistoryManager } from "~/contexts/history";
import useInitAppInterface from "~/hooks/cardoc-app/useInitAppInterface";
import { resetStyles } from "~/styles/reset";

dayjs.locale("ko");

export type NextPageWithLayout<T = {}> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<{
  dehydratedState: DehydratedState;
}> & {
  Component: NextPageWithLayout<any>;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // https://nextjs.org/docs/basic-features/layouts
  const getLayout = Component.getLayout ?? (page => page);
  const historyManager = useHistoryManager();
  useInitAppInterface();
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <>
      <Head>
        {/*
         * @author 장건우
         * @description iPhone, safe-area-inset 관련 설정
         * @url https://sohyunsaurus.tistory.com/34
         */}
        <meta
          name={"viewport"}
          content={
            "width=device-width, minimal-ui, viewport-fit=cover, initial-scale=1, maximum-scale=1"
          }
        />
      </Head>

      {IS_PROD && (
        <Script
          type={"text/javascript"}
          src={"https://cdn.imqa.io/agent/webview-agent.js"}
        />
      )}

      <Global
        styles={css`
                    ${resetStyles}
                `}
      />
      <Sentry.ErrorBoundary
        fallback={({ resetError }) => <ErrorBoundary resetError={resetError} />}
      >
        <HistoryManagerProvider value={historyManager}>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              <ReactQueryDevtools initialIsOpen={false} />
              {getLayout(<Component {...pageProps} />)}
            </HydrationBoundary>
          </QueryClientProvider>
        </HistoryManagerProvider>
      </Sentry.ErrorBoundary>
    </>
  );
};

export default App;
