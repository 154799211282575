import { useEffect, useLayoutEffect } from "react";

import { callAppInterface } from "~/utils/callAppInterface";

const useInitAppInterface = () => {
  useLayoutEffect(() => {
    window.setAppInfo = info => {
      window.app = JSON.parse(info);
      window.localStorage.setItem("accessToken", window?.app?.accessToken);
    };
    callAppInterface("getAppInfo", "setAppInfo");
  }, []);
};

export default useInitAppInterface;
